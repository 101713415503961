import React from 'react';

import { ContainerOneColumn, H1, H2 } from '_templates/Sections';

/**
 * The "Privacy Policy" section.
 *
 * @returns {React.Component} Section.
 */
const PrivacyPolicy = () => (
  <ContainerOneColumn>
    <H1>Privacy Policy</H1>
    <p>
      This privacy policy (&#8220;Policy&#8221;) describes how Pricematepay
      (&#8220;Pricematepay&#8221;, &#8220;we&#8221;, &#8220;us&#8221; or &#8220;our&#8221;)
      collects, protects and uses the personally identifiable information (&#8220;Personal
      Information&#8221;) you (&#8220;User&#8221;, &#8220;you&#8221; or &#8220;your&#8221;) may
      provide on the pricematepay.com website and any of its products or services (collectively,
      &#8220;Website&#8221; or &#8220;Services&#8221;). It also describes the choices available to
      you regarding our use of your Personal Information and how you can access and update this
      information. This Policy does not apply to the practices of companies that we do not own or
      control, or to individuals that we do not employ or manage.
    </p>
    <H2 textAlign="left">Collection of personal information</H2>
    <p>
      We receive and store any information you knowingly provide to us when you make a purchase,
      fill any online forms on the Website. You can choose not to provide us with certain
      information, but then you may not be able to take advantage of some of the Website&#8217;s
      features. Users who are uncertain about what information is mandatory are welcome to contact
      us.
    </p>
    <H2 textAlign="left">Collection of non-personal information</H2>
    <p>
      When you visit the Website our servers automatically record information that your browser
      sends. This data may include information such as your device&#8217;s IP address, browser type
      and version, operating system type and version, language preferences or the webpage you were
      visiting before you came to our Website, pages of our Website that you visit, the time spent
      on those pages, information you search for on our Website, access times and dates, and other
      statistics.
    </p>
    <H2 textAlign="left">Use of collected information</H2>
    <p>
      Any of the information we collect from you may be used to personalize your experience; improve
      our Website; improve customer service and respond to queries and emails of our customers;
      process transactions; run and operate our Website and Services. Non-Personal Information
      collected is used only to identify potential cases of abuse and establish statistical
      information regarding Website usage. This statistical information is not otherwise aggregated
      in such a way that would identify any particular user of the system.
    </p>
    <H2 textAlign="left">Billing and payments</H2>
    <p>
      We use third-party payment processors to assist us in processing your payment information
      securely. Such third-party processors&#8217; use of your Personal Information is governed by
      their respective privacy policies which may or may not contain privacy protections as
      protective as this Privacy Policy. We suggest that you review their respective privacy
      policies.
    </p>
    <H2 textAlign="left">Product and service providers</H2>
    <p>
      We may contract with other companies to provide certain products and services. These service
      providers are not authorized to use or disclose the information except as necessary to perform
      services on our behalf or comply with legal requirements. We may share Personal Information
      for these purposes only with third-parties whose privacy policies are consistent with ours or
      who agree to abide by our policies with respect to Personal Information Our service providers
      are given the information they need to perform their designated functions, and we do not
      authorize them to use or disclose Personal Information for their own marketing or other
      purposes.
    </p>
    <H2 textAlign="left">Privacy of children</H2>
    <p>
      We do not knowingly collect any Personal Information from children under the age of 13. If you
      are under the age of 13, please do not submit any Personal Information through our Website or
      Service. We encourage parents and legal guardians to monitor their children&#8217;s Internet
      usage and to help enforce this Policy by instructing their children never to provide Personal
      Information through our Website or Service without their permission. If you have reason to
      believe that a child under the age of 13 has provided Personal Information to us through our
      Website or Service, please contact us. You must also be old enough to consent to the
      processing of your personal data in your country (in some countries we may allow your parent
      or guardian to do so on your behalf).
    </p>
    <H2 textAlign="left">Do Not Track signals</H2>
    <p>
      Some browsers incorporate a Do Not Track feature that signals to websites you visit that you
      do not want to have your online activity tracked. Tracking is not the same as using or
      collecting information in connection with a website. For these purposes, tracking refers to
      collecting personally identifiable information from consumers who use or visit a website or
      online service as they move across different websites over time. Our Website does not track
      its visitors over time and across third party websites. However, some third party sites may
      keep track of your browsing activities when they serve you content, which enables them to
      tailor what they present to you.
    </p>
    <H2 textAlign="left">Links to other websites</H2>
    <p>
      Our Website contains links to other websites that are not owned or controlled by us. Please be
      aware that we are not responsible for the privacy practices of such other websites or
      third-parties. We encourage you to be aware when you leave our Website and to read the privacy
      statements of each and every website that may collect Personal Information.
    </p>
    <H2 textAlign="left">Information security</H2>
    <p>
      We secure information you provide on computer servers in a controlled, secure environment,
      protected from unauthorized access, use, or disclosure. We maintain reasonable administrative,
      technical, and physical safeguards in an effort to protect against unauthorized access, use,
      modification, and disclosure of Personal Information in its control and custody. However, no
      data transmission over the Internet or wireless network can be guaranteed. Therefore, while we
      strive to protect your Personal Information, you acknowledge that (i) there are security and
      privacy limitations of the Internet which are beyond our control; (ii) the security,
      integrity, and privacy of any and all information and data exchanged between you and our
      Website cannot be guaranteed; and (iii) any such information and data may be viewed or
      tampered with in transit by a third-party, despite best efforts.
    </p>
    <H2 textAlign="left">Data breach</H2>
    <p>
      In the event we become aware that the security of the Website has been compromised or users
      Personal Information has been disclosed to unrelated third parties as a result of external
      activity, including, but not limited to, security attacks or fraud, we reserve the right to
      take reasonably appropriate measures, including, but not limited to, investigation and
      reporting, as well as notification to and cooperation with law enforcement authorities. In the
      event of a data breach, we will make reasonable efforts to notify affected individuals if we
      believe that there is a reasonable risk of harm to the user as a result of the breach or if
      notice is otherwise required by law. When we do, we will send you an email.
    </p>
    <H2 textAlign="left">Changes and amendments</H2>
    <p>
      We reserve the right to modify this Policy relating to the Website or Services at any time,
      effective upon posting of an updated version of this Policy on the Website. When we do we will
      revise the updated date at the bottom of this page. Continued use of the Website after any
      such changes shall constitute your consent to such changes.
    </p>
    <H2 textAlign="left">Acceptance of this policy</H2>
    <p>
      You acknowledge that you have read this Policy and agree to all its terms and conditions. By
      using the Website or its Services you agree to be bound by this Policy. If you do not agree to
      abide by the terms of this Policy, you are not authorized to use or access the Website and its
      Services.
    </p>
    <H2 textAlign="left">Contacting us</H2>
    <p>If you have any questions about this Policy, please contact us.</p>
    <p>This document was last updated on March 10, 2021.</p>
  </ContainerOneColumn>
);

export default PrivacyPolicy;
